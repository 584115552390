<template>
    <div id="app">
      <el-row :gutter="24">

        <el-col :md="{span: 24}" style="margin-top: 10px;">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
            <span>
              添加計劃任務
            </span>
            </div>
            <div class="text item">
              <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">

                <el-col :span="24">
                  <el-form-item label="任務名稱" prop="title">
                    <el-input style="width: 300px;" placeholder="填寫任務名稱" v-model="ruleForm.title"></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="24">
                  <el-form-item label="執行週期" prop="cycle" >

                    <el-select v-model="ruleForm.cycle" placeholder="請選擇" style="float: left;margin-right: 10px;">
                      <el-option
                          v-for="item in crontabCycle"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                          :disabled="item.disabled">
                      </el-option>
                    </el-select>


                  </el-form-item>

                </el-col>

                <el-col :span="24">
                  <el-form-item label="任務類型" prop="type">
                    <el-select v-model="ruleForm.type" placeholder="請選擇" >
                      <el-option
                          v-for="item in crontabType"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                          :disabled="item.disabled">
                      </el-option>
                    </el-select>

                  </el-form-item>
                </el-col>

                <el-col :span="24">
                  <el-form-item label="URL地址">
                    <el-input style="width: 300px;" placeholder="填寫地址" v-model="ruleForm.url" ></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="24">
                  <div style="margin-bottom: 10px;">
                    <el-button @click="resetForm('ruleForm')">重置</el-button>
                    <el-button type="success" @click="submitForm('ruleForm')">
                      添加任務
                    </el-button>
                  </div>
                </el-col>
              </el-form>
            </div>
          </el-card>
        </el-col>

        <el-col :md="{span: 24}" style="margin-top: 10px;">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
            <span>
              任務列表
            </span>
            </div>
            <div class="text item">
              <el-table
                  class="lists"
                  ref="RoomTable"
                  :data="data.list"
                  :height="data.total?clientHeight:'100'"
                  v-loading="loading"
                  border
                  style="width: 100%;">
                <el-table-column
                    fixed
                    prop="title"
                    label="任務名稱"
                    width="200">
                </el-table-column>
                <el-table-column
                    label="任務類型">
                    <template slot-scope="scope">
                    <div style="text-align: center;">
                        {{getCrontabType(scope.row.type)}}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                    label="任務週期">
                    <template slot-scope="scope">
                    <div style="text-align: center;">
                        {{getCrontabCycle(scope.row.cycle)}}
                    </div>
                  </template>
                </el-table-column>
                <!-- <el-table-column prop="url" label="URL地址">
                </el-table-column>
                <el-table-column prop="shell" label="Shell脚本">
                </el-table-column> -->
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="130">
                  <template slot-scope="scope">
                    <div style="text-align: center;">
                        <el-button size="mini" type="danger" @click="del(scope.row.id)">删除任務</el-button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <div class="pager">
                <el-pagination
                    background
                    layout="prev, pager, next"
                    :current-change="handleCurrentChange"
                    :page-size="data.pageSize"
                    :total="data.total">
                </el-pagination>
              </div>
            </div>
          </el-card>
        </el-col>

      </el-row>
    </div>
</template>

<script>
    export default {
        data () {
            return {
              query:'',
              data:{
                total:0,
                pageSize:0,
                list:[]
              },
              page:1,
              clientHeight:'400',
              ruleForm:{
                type: '',
                cycle: '',
                title: '',
                url: '',
                shell: ''
              },
              loading:false,
              crontabType:[
                {
                  value: 'toUrl',
                  label: '访问URL'
                },
                {
                  value: 'toShell',
                  label: 'Shell脚本',
                  disabled: true
                },
                {
                  value: 'database',
                  label: '备份数据库',
                  disabled: true
                }
              ],
              crontabCycle:[
                {
                  value: 'day',
                  label: '每天'
                },
                {
                  value: 'week',
                  label: '每周'
                },
                {
                  value: 'hour',
                  label: '每小时'
                },
                {
                  value: 'month',
                  label: '每分钟',
                },
                {
                  value: 'second',
                  label: '每秒',
                  disabled: true
                }
              ],
              rules: {
                title : [
                  { required: true, message: '请输入任务名称', trigger: 'blur' }
                ],
                cycle:[
                  { required: true, message: '请选择执行周期', trigger: 'blur' }
                ],
                type:[
                  { required: true, message: '请选择任务类型', trigger: 'blur' }
                ]
              },

            }
        },
        mounted(){
          this.getList()
        },
        methods:{
          getCrontabType(key){
            let data = this.crontabType
            for(let o in data){
              if(data[o].value == key){
                return data[o].label
              }
            }
          },
          getCrontabCycle(key){
            let data = this.crontabCycle
            for(let o in data){
              if(data[o].value == key){
                return data[o].label
              }
            }
          },
          // 提交
          submitForm(formName) {
            let that = this
            that.$refs[formName].validate((valid) => {
              if (valid) {
                // 访问URL 判断
                if(that.ruleForm.type == 'toUrl'){
                  if(that.ruleForm.url.substr(0,7).toLowerCase() == "http://" || that.ruleForm.url.substr(0,8).toLowerCase() == "https://"){
                    if(that.ruleForm.url.length <= 8){
                      that.$notify({
                        title: '错误提示',
                        message: 'URL地址不正确!',
                        type: 'warning'
                      });
                      return
                    }
                  }else {
                      that.$notify({
                        title: '错误提示',
                        message: 'URL地址不正确!',
                        type: 'warning'
                      });
                      return
                  }
                }
                that.loading=true
                that.$http.POST('/v1/crontab/add',that.ruleForm,function(ret){
                if(ret.errcode == 0){
                  that.getList(that.page)
                  that.loading=false
                  that.$notify({
                    title: "成功",
                    message: ret.errmsg,
                    type: "success",
                  });
                }else if(ret.errcode >= 1){
                  that.loading=false
                  that.$message({
                    message: ret.errmsg,
                    type: 'warning'
                  });
                }
              },function(err){
                that.loading=false
                that.$notify({
                  title: '警告',
                  message: err,
                  type: 'warning'
                });
              })
              } else {
                return false;
              }
            });
          },
          // 重置
          resetForm(formName) {
            this.$refs[formName].resetFields();
          },
          // 获取账户列表
          getList(page=1){
            let that = this
            let param=that.$atk.json_to_url({
              page:page||parseInt(that.page),
              query:that.query
            })
            that.loading=true
            this.$http.GET('/v1/crontab/getCrontabList?'+param,function(ret){
              if(ret.errcode == 0){
                that.loading=false
                that.data = ret.data
              }else if(ret.errcode >= 1){
                that.loading=false
                that.$message({
                  message: ret.errmsg,
                  type: 'warning'
                });
              }
            },function(err){
              that.loading=false
              that.$notify({
                title: '警告',
                message: err,
                type: 'warning'
              });
            })
          },
          // 删除
          del(id) {
            let that = this;
            let param = that.$atk.json_to_url({
              id: id,
            });

            that.$confirm("您确认要删除吗?", "删除提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
            .then(() => {
              that.$http.DELETE(
                "/v1/crontab/del?" + param,
                function (ret) {
                  if (ret.errcode == 0) {
                    that.$notify({
                      title: "成功",
                      message: ret.errmsg,
                      type: "success",
                    });
                    that.deleteItem(id, that.data.list);
                  } else if (ret.errcode == 1) {
                    that.$notify.error({
                      title: "错误",
                      message: ret.errmsg,
                    });
                  }
                },
                function (err) {
                  that.$notify({
                    title: "警告",
                    message: err,
                    type: "warning",
                  });
                }
              );
            })
            .catch(() => {
              that.$notify({
                title: "回收提示",
                message: "已取消回收用户",
                type: "warning",
              });
            });

          },
          // 删除列表用户
          deleteItem(item, list) {
            for (let key in this.data.list) {
              if (list[key].id === item) {
                list.splice(key, 1);
              }
            }
          },
          // 分页
          handleCurrentChange(val) {
            this.page = val
            this.getList(val)
          },
        }
    }
</script>
<style scoped lang="scss">

</style>
